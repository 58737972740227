import React, { FC } from 'react'
import useTranslation from 'next-translate/useTranslation'
import { useUI } from '@components/ui/context'

import { Button } from '@components/ui'

import s from './Success.module.css'
import cn from 'classnames'

interface SuccessProps {
  layout?: string
  autoDisplay?: boolean
  isFormFullDisplayed?: boolean
}

const Success: FC<SuccessProps> = ({
  layout,
  autoDisplay,
  isFormFullDisplayed,
}) => {
  const { t } = useTranslation()
  const { closeModal } = useUI()

  return (
    <div
      className={cn(
        s.container,
        { [s.darkTheme]: autoDisplay },
        { [s.fullHeight]: isFormFullDisplayed }
      )}
    >
      <h2 className={s.title}>{t('newsletter:success.title')}</h2>
      <p className={s.desc}>{t('newsletter:success.info')}</p>
      {layout !== 'page' && !autoDisplay && (
        <Button className={s.btn} onClick={() => closeModal()}>
          {t('newsletter:close')}
        </Button>
      )}
    </div>
  )
}

export default Success
