import React, { FC, FormEvent, useEffect, useState } from 'react'
import useTranslation from 'next-translate/useTranslation'
import { useRouter } from 'next/router'
import { useUserSettings } from '@context/session'

import Alert from '@components/common/Alert'
import { Status } from '@components/common/Alert/Alert'
import { Button, DynamicInput, FormRadioInput, Selector } from '@components/ui'

import { EMAIL_REGEXP, LOCALES } from '@constants'
import { sortCountriesAlphabetically } from '@utils/countries'
import { tagEventNewsletterInscription } from '@components/common/Eulerian/EulerianEvents'
import { tagEventCS } from '@components/common/ContentSquare'

import s from './Form.module.css'
import cn from 'classnames'
import { useNewsletterContext } from '@context/newsletter'

interface Props {
  countries?: Array<Country>
  emailProp: string
  autoDisplay?: boolean
  isDesktop?: boolean
  onSuccess?: () => void
  onAlreadySubscribed?: () => void
  onError?: () => void
  isFormFullDisplayed?: boolean
  setIsFormFullDisplayed?: (isFormFullDisplayed: boolean) => void
}

enum Error {
  InvalidEmail = 'invalid email',
}

const Form: FC<Props> = ({
  countries,
  emailProp,
  autoDisplay,
  isDesktop,
  onSuccess = () => {},
  onAlreadySubscribed = () => {},
  onError = () => {},
  isFormFullDisplayed,
  setIsFormFullDisplayed,
}) => {
  const { t } = useTranslation()
  const userSettings = useUserSettings()
  const { locale } = useRouter()
  const { getNewsletterDiscount, getNewsletterMinimumOrder } =
    useNewsletterContext()

  const defaultLocale = LOCALES?.find(
    (currentLocale) => currentLocale.locale === locale
  )
  const defaultCountry = countries?.find(
    (currentCountry) =>
      currentCountry.isoCode === userSettings?.deliveryCountryCode
  )
  const [title, setTitle] = useState(2)
  const [lastname, setLastname] = useState(userSettings?.lastname || '')
  const [firstname, setFirstname] = useState(userSettings?.firstname || '')
  const [email, setEmail] = useState(emailProp || userSettings?.email || '')
  const [language, setLanguage] = useState(defaultLocale?.id || '')
  const [country, setCountry] = useState(defaultCountry?.id || '')

  const titles = [
    {
      value: 2,
      option: t(`newsletter:form.title.mrs`),
    },
    {
      value: 1,
      option: t(`newsletter:form.title.m`),
    },
    {
      value: 4,
      option: t(`newsletter:form.title.other`),
    },
  ]

  const languages = LOCALES.map((locale) => {
    return {
      option: t(`newsletter:form.language.${locale.locale}`),
      value: locale.id,
    }
  })

  const countriesList = sortCountriesAlphabetically(countries).map(
    (country) => {
      return {
        option: country.name,
        value: country.id,
      }
    }
  )

  const [state, setState] = useState<{
    status: Status
    message: string
    type: Error | null
  } | null>(null)

  const submitNewsletterForm = async () => {
    const body = {
      title,
      lastname,
      firstname,
      email,
      language,
      country,
    }

    try {
      const res = await fetch(
        `${process.env.NEXT_PUBLIC_SMALLABLE_API_URL || ''}/v1/newsletter`,
        {
          method: 'POST',
          mode: 'cors',
          credentials: 'include',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(body),
        }
      )
      if (!res?.ok) throw Error
      const { data } = await res.json()

      const alreadySubscribed =
        data.messageKey === 'newsletter_email_already_subscribed'
      if (alreadySubscribed) {
        onAlreadySubscribed()
        if (autoDisplay)
          tagEventCS({ eventName: 'newsletter_proposal_accepted' })
        else tagEventCS({ eventName: 'newsletter_suscribe' })
      } else {
        onSuccess()
      }

      const urlp = alreadySubscribed
        ? '/newsletter/deja-inscrit'
        : '/newsletter/nouvel_inscription'
      tagEventNewsletterInscription({
        urlp,
        locale,
        sessionData: userSettings,
        emailMd5Newsletter: data?.newsletterEmailMd5 || null,
      })
    } catch (err) {
      onError()
    }
  }

  const handleShowMore = () => {
    setIsFormFullDisplayed(true)
  }

  const handleSubmit = async (event: FormEvent, mail: string | undefined) => {
    event.preventDefault()

    if (!mail || !mail.match(EMAIL_REGEXP)) {
      setState({
        status: Status.Error,
        message: t('product:error_invalidEmail'),
        type: Error.InvalidEmail,
      })
    } else {
      submitNewsletterForm()
    }
  }

  useEffect(() => {
    if (!isDesktop) setIsFormFullDisplayed(false)
    else setIsFormFullDisplayed(true)
  }, [isDesktop])

  return (
    <div className={cn(s.container, { [s.darkTheme]: autoDisplay })}>
      <h2 className={cn(s.title, { [s.darkTheme]: autoDisplay })}>
        {t('newsletter:title', { discount: getNewsletterDiscount() })}
      </h2>
      {isFormFullDisplayed ? (
        <>
          <p className={cn(s.desc, { [s.darkTheme]: autoDisplay })}>
            {t('newsletter:info.1', { discount: getNewsletterDiscount() })}
          </p>
          <p
            className={cn(s.desc, s.italicText, { [s.darkTheme]: autoDisplay })}
          >
            {t('newsletter:info.2')}{' '}
            <span className={s.smiley}>{t('newsletter:info.3')}</span>
          </p>

          <form
            className={s.form}
            method="POST"
            action="#"
            onSubmit={(e) => handleSubmit(e, email)}
          >
            <fieldset className={s.containerField}>
              <FormRadioInput
                name="gender"
                options={titles}
                className={cn(s.radioInput)}
                isDarkTheme={autoDisplay}
                setData={(e) => setTitle(Number(e?.data))}
                label={t(`newsletter:form.title.name`)}
                required
                defaultSelected={2}
              ></FormRadioInput>
            </fieldset>
            <fieldset className={cn(s.containerField, s.inlineInputs)}>
              <DynamicInput
                id="inputFirstname"
                isDarktheme={autoDisplay}
                label={t('newsletter:form.firstname')}
                inputName="firstname"
                type="text"
                ariaLabel="firstname"
                onChange={(e) => setFirstname(e.target.value)}
                required
              ></DynamicInput>
              <DynamicInput
                id="inputLastname"
                isDarktheme={autoDisplay}
                label={t('newsletter:form.lastname')}
                inputName="lastname"
                type="text"
                ariaLabel="lastname"
                onChange={(e) => setLastname(e.target.value)}
                required
              ></DynamicInput>
            </fieldset>
            {!autoDisplay ? (
              <fieldset>
                <DynamicInput
                  isDarktheme={autoDisplay}
                  id="inputEmail"
                  label={t('newsletter:form.email')}
                  inputName="firstname"
                  value={email}
                  type="text"
                  ariaLabel="firstname"
                  onChange={(e) => setEmail(e.target.value)}
                  required
                ></DynamicInput>
              </fieldset>
            ) : null}
            <fieldset className={cn(s.containerField, s.inlineInputs)}>
              <Selector
                options={languages}
                className={s.select}
                label={t('newsletter:form.language.name')}
                isDarkTheme={autoDisplay}
                onChange={(e) => setLanguage(Number(e.target.value))}
                defaultSelected={defaultLocale?.id}
              ></Selector>
              <Selector
                options={countriesList}
                className={s.select}
                label={t('newsletter:form.country')}
                isDarkTheme={autoDisplay}
                onChange={(e) => setCountry(Number(e.target.value))}
                defaultSelected={country}
              ></Selector>
            </fieldset>
            {autoDisplay ? (
              <fieldset className={s.submissionLine}>
                <DynamicInput
                  isDarktheme={autoDisplay}
                  id="inputEmail"
                  label={t('newsletter:form.email')}
                  inputName="firstname"
                  type="text"
                  ariaLabel="firstname"
                  onChange={(e) => setEmail(e.target.value)}
                  required
                ></DynamicInput>
                <div className={cn(s.btnPosition, s.inlineButton)}>
                  <Button
                    className={cn(s.btn, { [s.darkTheme]: autoDisplay })}
                    type="submit"
                  >
                    {t('newsletter:submit')}
                  </Button>
                </div>
              </fieldset>
            ) : (
              <div className={s.btnPosition}>
                <Button className={cn(s.btn)} type="submit">
                  {t('newsletter:submit')}
                </Button>
              </div>
            )}
            <p className={cn(s.miniDesc, { [s.darkTheme]: autoDisplay })}>
              {t('layout:footer.newsletter.annotation', {
                minimumOrder: getNewsletterMinimumOrder(),
              })}
            </p>

            {state && state.status && state.message && (
              <div className={s.alertContainer}>
                <Alert status={state.status} message={state.message} />
              </div>
            )}
          </form>
        </>
      ) : (
        <div className={s.btnPosition}>
          <Button className={cn(s.btn, s.darkTheme)} onClick={handleShowMore}>
            {t('newsletter:submit')}
          </Button>
        </div>
      )}
    </div>
  )
}

export default Form
