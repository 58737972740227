import React, { FC, useRef, useEffect, useState } from 'react'
import { useUI } from '@components/ui/context'

import Form from './Form'
import Success from './Success'
import Error from './Error'
import AlreadySubscribed from './AlreadySubscribed'
import { Modal } from '@components/ui'
import { Cross } from '@components/icons'

import cn from 'classnames'
import s from './NewsletterModal.module.css'
import { NewsletterProvider } from '@context/newsletter'

export interface NewsletterModalProps {
  open?: boolean
  closeModal?: () => void
  countries?: Array<Country>
  layout?: string
  emailProp?: string
  autoDisplay?: boolean
  isDesktop?: boolean
}

enum STATE {
  FORM = 'FORM',
  SUCCESS = 'SUCCESS',
  ALREADY_SUBSCRIBED = 'ALREADY_SUBSCRIBED',
  ERROR = 'ERROR',
}

export const NewsletterModalView: FC<NewsletterModalProps> = ({
  countries,
  layout = 'modal',
  emailProp = '',
  autoDisplay = false,
  isDesktop = true,
}) => {
  const { closeModal } = useUI()

  const startToFill = useRef<boolean>(false)
  const [isFormFullDisplayed, setIsFormFullDisplayed] = useState(isDesktop)
  const [isClosed, setIsClosed] = useState(false)
  const [newsletterState, setNewsletterState] = useState<STATE>(STATE.FORM)

  const onSuccess = () => setNewsletterState(STATE.SUCCESS)
  const onAlreadySubscribed = () => setNewsletterState(STATE.ALREADY_SUBSCRIBED)
  const onError = () => setNewsletterState(STATE.ERROR)

  const handleClose = () => {
    setIsClosed(true)
    setTimeout(() => {
      closeModal()
    }, 2000)
  }

  const handleFocus = () => {
    if (!startToFill.current) startToFill.current = true
  }

  useEffect(() => {
    if (newsletterState === STATE.FORM || newsletterState === STATE.ERROR)
      return null
    let timer = setTimeout(() => {
      handleClose()
    }, 4000)

    return () => clearTimeout(timer)
  }, [newsletterState])

  useEffect(() => {
    if (autoDisplay) {
      let timer = setTimeout(() => {
        if (!startToFill.current) handleClose()
      }, 8000)

      return () => clearTimeout(timer)
    }
  }, [autoDisplay])

  useEffect(() => {
    if (autoDisplay && !isDesktop) setIsFormFullDisplayed(false)
    else setIsFormFullDisplayed(true)
  }, [autoDisplay])

  const COMPONENT_CONTENT: Record<string, JSX.Element> = {
    FORM: (
      <NewsletterProvider>
        <Form
          countries={countries}
          emailProp={emailProp}
          autoDisplay={autoDisplay}
          onSuccess={onSuccess}
          onAlreadySubscribed={onAlreadySubscribed}
          onError={onError}
          isDesktop={isDesktop}
          setIsFormFullDisplayed={setIsFormFullDisplayed}
          isFormFullDisplayed={isFormFullDisplayed}
        />
      </NewsletterProvider>
    ),
    SUCCESS: (
      <Success
        layout={layout}
        autoDisplay={autoDisplay}
        isFormFullDisplayed={isFormFullDisplayed}
      />
    ),
    ALREADY_SUBSCRIBED: (
      <AlreadySubscribed
        layout={layout}
        autoDisplay={autoDisplay}
        isFormFullDisplayed={isFormFullDisplayed}
      />
    ),
    ERROR: (
      <Error
        layout={layout}
        autoDisplay={autoDisplay}
        isFormFullDisplayed={isFormFullDisplayed}
      />
    ),
  }

  return (
    <div
      className={cn(
        s.container,
        { [s.autoDisplay]: autoDisplay },
        { [s.slideOut]: isClosed },
        { [s.full]: layout === 'page' },
        { [s.mobile]: autoDisplay && !isDesktop },
        { [s.open]: autoDisplay && isFormFullDisplayed }
      )}
    >
      <div
        onFocus={autoDisplay ? () => handleFocus() : undefined}
        className={cn(s.modal, {
          [s.fullHeight]: autoDisplay && isFormFullDisplayed,
        })}
      >
        {autoDisplay ? (
          <button
            onClick={() => {
              handleClose()
            }}
            aria-label="Close panel"
            className={s.close}
          >
            <Cross stroke="#fff" />
          </button>
        ) : null}
        {COMPONENT_CONTENT[newsletterState]}
      </div>
    </div>
  )
}

const NewsLetterModal: FC<NewsletterModalProps> = ({ ...props }) => {
  if (props.autoDisplay && props.open) return <NewsletterModalView {...props} />
  return (
    <Modal open={props.open} onClose={props.closeModal}>
      <NewsletterModalView {...props} />
    </Modal>
  )
}

export default NewsLetterModal
